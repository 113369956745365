<template>
  <Faq
    :items="faq"
    :loading="pageDataLoading"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Faq from 'organisms/FAQ/FAQ'

export default {
  components: {
    Faq
  },
  data: () => ({
    faqLoading: false
  }),
  computed: {
    ...mapGetters(['faq', 'pageDataLoading'])
  },
  mounted() {
    this.getFAQ()
  },
  methods: {
    ...mapActions(['getFAQ'])
  }
}
</script>

<style lang="scss">
</style>
