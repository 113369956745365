<template>
  <VuePerfectScrollbar class="e-content__inner e-content__inner--wide e-faq e-faq__inner">
    <div class="e-faq__content">
      <div v-if="loading">
        <Loading />
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-for="(item, index) in items"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <h4 class="el-collapse-item__title">
              {{ item.question | noCategory }}
            </h4>
            <div class="el-collapse-item__subtitle">
              {{ item.question | category }}
            </div>
          </template>
          <div
            v-html="item.answer"
          />
        </el-collapse-item>
      </el-collapse>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Loading from 'atoms/Loading/Loading'

export default {
  name: 'FaqWrapper',
  filters: {
    noCategory(value) {
      return value && value.indexOf(':') > -1
        ? value.substring(value.indexOf(':') + 2, value.length)
        : value
    },
    category(value) {
      return value && value.indexOf(':') > -1 ? value.substring(0, value.indexOf(':')) : ''
    }
  },
  components: {
    Loading,
    VuePerfectScrollbar
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
    activeNames: []
  })
}
</script>

<style lang="scss">
@import '_assets/faq';
</style>
<style lang="scss" scoped>
.e-faq__inner {
  &.ps {
    overflow: auto !important;

    @include break(l) {
      overflow: hidden !important;
      @at-root .mobile {
        overflow: auto !important;
      }
    }
  }
}
</style>
